<template>
	<ProductCompareForm :is-loading="create.isCreating" @onSubmit="handleCreateProductCompare" />
</template>

<script>
import ProductCompareForm from '@/components/ProductCompareForm.vue';
import { mapActions, mapState } from 'vuex';

export default {
	name: 'ProductCompareCreate',

	components: {
		ProductCompareForm,
	},
	computed: {
		...mapState('productCompares', {
			create: 'create',
		}),
	},
	methods: {
		...mapActions({
			createProductCompare: 'productCompares/createProductCompare',
		}),
		async handleCreateProductCompare(payload) {
			await this.createProductCompare(payload);
			this.$router.push('/product-compare');
		},
	},
};
</script>
